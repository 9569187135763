// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-background-js": () => import("./../src/pages/background.js" /* webpackChunkName: "component---src-pages-background-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-courses-js": () => import("./../src/pages/courses.js" /* webpackChunkName: "component---src-pages-courses-js" */),
  "component---src-pages-demo-form-js": () => import("./../src/pages/demo-form.js" /* webpackChunkName: "component---src-pages-demo-form-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-intro-js": () => import("./../src/pages/intro.js" /* webpackChunkName: "component---src-pages-intro-js" */),
  "component---src-pages-overview-js": () => import("./../src/pages/overview.js" /* webpackChunkName: "component---src-pages-overview-js" */),
  "component---src-pages-patreon-js": () => import("./../src/pages/patreon.js" /* webpackChunkName: "component---src-pages-patreon-js" */),
  "component---src-pages-resources-js": () => import("./../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-sign-up-js": () => import("./../src/pages/sign-up.js" /* webpackChunkName: "component---src-pages-sign-up-js" */),
  "component---src-pages-struggles-js": () => import("./../src/pages/struggles.js" /* webpackChunkName: "component---src-pages-struggles-js" */),
  "component---src-pages-successes-js": () => import("./../src/pages/successes.js" /* webpackChunkName: "component---src-pages-successes-js" */),
  "component---src-pages-thank-you-free-online-course-1155245-js": () => import("./../src/pages/thank-you-free-online-course-1155245.js" /* webpackChunkName: "component---src-pages-thank-you-free-online-course-1155245-js" */),
  "component---src-pages-uses-js": () => import("./../src/pages/uses.js" /* webpackChunkName: "component---src-pages-uses-js" */)
}

